import { Localization } from '@mui/material/locale';
import { Breakpoints, Components, Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Locale } from 'date-fns';
import { ReactNode } from 'react';
import { ConfigFilterPrefixes, ConfigInfoGridPrefixes } from '../_tools/utils';
import { Car, CarKategorie, Filter, type ViewOptionsView } from '../_types/Car';
import { IconifyProps } from '../components/iconify/types';

export enum Lang {
    deDE = 'de-DE',
    enUs = 'en-US',
    frFr = 'fr-FR',
}

export type Language = {
    label: string,
    value: Lang,
    systemValue: Localization,
    icon: IconifyProps,
    locale: Locale,
};

export enum TrackingProvider {
    ETracker = 'etracker',
    Octo = 'octo',
}

export type CarDataFields = (
    'antrieb'
    | 'engine.hubraum'
    | 'engine.leistung'
    | 'engine.motor'
    | 'ez'
    | 'farbe'
    | 'garantie'
    | 'getriebe'
    | 'ident'
    | 'kategorie'
    | 'km'
    | 'siegel'
    | 'siegel_garantie'
    | 'sitze'
    | 'spacer'
    | 'standort'
    | 'tueren'
    | 'typ'
    | 'verbrauch.combined'
    | 'verbrauch.combinedDischarged'
    | 'verbrauch.combinedWeighted'
    | 'verbrauch.combinedWeightedShort'
    | 'verbrauch.co2costsLow'
    | 'verbrauch.co2costsMedium'
    | 'verbrauch.co2costsHigh'
    | 'verbrauch.co2class'
    | 'verbrauch.effizienzBig'
    | 'verbrauch.effizienzDischarged'
    | 'verbrauch.effizienzWeighted'
    | 'verbrauch.emission'
    | 'verbrauch.emissionDischarged'
    | 'verbrauch.emissionWeighted'
    | 'verbrauch.emissionWeightedShort'
    | 'verbrauch.energycosts'
    | 'verbrauch.euronorm'
    | 'verbrauch.infoButton'
    | 'verbrauch.title'
    | 'verbrauch.wltp.low'
    | 'verbrauch.wltp.lowDischarged'
    | 'verbrauch.wltp.medium'
    | 'verbrauch.wltp.mediumDischarged'
    | 'verbrauch.wltp.high'
    | 'verbrauch.wltp.highDischarged'
    | 'verbrauch.wltp.extraHigh'
    | 'verbrauch.wltp.extraHighDischarged'
    | 'verbrauch.wltp.range'
);

export type CarDataField = {
    i: CarDataFields,
    fullWidth?: boolean,
    headingExtraLine?: boolean,
    icon: (car) => IconifyProps,
    lineBreak: boolean,
    noTooltip?: boolean,
    noTypo?: boolean,
    renderTextValue?: (car: Car<false>) => string,
    renderValue?: (car: Car<false>) => ReactNode,
    t: string,
};

export type ConfigFilter = (
    'hersteller' | 'modell' | 'typ' | 'kategorie' | 'aufbau' | 'aufbauten' | 'siegel' | 'standort' | 'preis' | 'preisVon' | 'preisBis' | 'fd' | 'rate' | 'rateVon' | 'rateBis' | 'ez' | 'ezVon' | 'ezBis' | 'leistung' | 'leistungVon' | 'leistungBis' | 'km' | 'kmVon' | 'kmBis' | 'motor' | 'getriebe' | 'aus' | 'sofort' | 'aktion' | 'spacer' | 'tags' | 'farbe'
);
export type ConfigFilterOther = (
    'monatlicheRateButton' | 'sortButton' | 'shareButton' | 'advancedSearchButton' | 'resetSearchButton' | 'volltext' | 'gridviewButton' | 'listviewButton' | 'standortMapButton'
);

export type ShareButtons = ('telegram' | 'whatsapp');
export type ConfigFilterObject = {
    /** Order of shown filters in sidebar.
     * @default ['sofort', 'hersteller', 'modell', 'standort', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus']
     */
    sidebar: ConfigFilter[],
    /** Order of shown filters on top.
     * @default ['[SM]hersteller', '[SM]modell', '[LG]sofort', '[LG]motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
     */
    top: (ConfigFilterPrefixes<ConfigFilter> | ConfigFilterOther)[],
};

export type FahrzeuglisteFilter = {
    /** Only show Aktionsfahrzeuge.
     * @default undefined
     */
    aktion?: number | undefined,
    /** Fahrzeugtypen.
     * @default undefined
     */
    akttypList?: number[] | undefined,
    /** Filter by "Aufbau".
     * @default undefined
     */
    aufbau?: number | undefined,
    /** Filter by "Aufbauten", Aufbau als Multiselect.
     * @default undefined
     */
    aufbauten?: number[] | undefined,
    /** First registration.
     * @default undefined
     */
    EZ?: number[] | undefined,
    /** Farbe.
     * @default undefined
     */
    Farben?: number[] | undefined,
    /** Finanzdienstleistungen.
     * @default undefined
     */
    fd?: number | undefined,
    /** Only show Finanzierungsfahrzeuge.
     * @default undefined
     */
    fiz?: number | undefined,
    /** Transmission.
     * @default undefined
     */
    Getriebe?: number | undefined,
    /** Hersteller.
     * @default undefined
     */
    hersteller0?: number | undefined,
    /** Ausstattung.
     * @default undefined
     */
    ia?: [number] | undefined,
    /** Company ID.
     * @default undefined
     */
    idFirma?: number[] | undefined,
    /** Company ID that gets ignored.
     * @default undefined
     */
    ignoreFirma?: number[] | undefined,
    /** Location ID that gets ignored.
     * @default undefined
     */
    ignoreStandort?: number[] | undefined,
    /** Filter by "Kategorie".
     * @default undefined
     */
    kategorie?: number | undefined,
    /** KM.
     * @default undefined
     */
    KM?: number[] | undefined,
    /** Power.
     * @default undefined
     */
    Leistung?: number[] | undefined,
    /** Modell.
     * @default undefined
     */
    modell0?: number | undefined,
    /** Motor.
     * @deprecated
     * @default undefined
     */
    motorG?: number | undefined,
    /** Motoren.
     * @default undefined
     */
    motoren?: number[] | undefined,
    /** Only request facets.
     * @default undefined
     */
    onlyFacetten?: number | undefined,
    /** Page number.
     * @default undefined
     */
    page?: number | undefined,
    /** Page size.
     * @default undefined
     */
    pageSize?: number | undefined,
    /** Price.
     * @default undefined
     */
    Preis?: number[] | undefined,
    /** Monthly rate.
     * @default undefined
     */
    Rate?: number[] | undefined,
    /** Seal.
     * @default undefined
     */
    siegel?: number | undefined,
    /** Now available.
     * @default undefined
     */
    sofort?: boolean | undefined,
    /** Overwrite sorting.
     * @default undefined
     */
    sort?: string | undefined,
    /** Location filter.
     * @default undefined
     */
    Standort?: number[] | undefined,
    /** Overwrite "Aktion" with dropdown "Aktion"-select.
     * @default undefined
     */
    tags?: string | undefined,
};

type ModuleCommonProps = {
    /** Default Filter.
     * @default undefined
     */
    defaultFilter: string | undefined,
    /** Default Sort.
     * @default undefined
     */
    defaultSort: { order: Filter['order'], sort: Filter['sort'] } | undefined,
    /** Force filters to be set permanently.
     * @default { }
     */
    forcedFilter: FahrzeuglisteFilter,
    /** Limit the maximum column count.
     * @default undefined
     */
    limitCols: number | undefined,
    /** Turn off animations.
     * @default false
     */
    noAnimations: boolean,
};

/**
 * Configuration object.
 * @example
 * {
    general: {
        idFirma: 13890,
        enable: true,
        metaTitleSuffix: 'Autohaus Liliensiek',
        searchPath: '/fahrzeugsuche',
    },
    contact: {
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung'
    },
    module: {
        aktionsslider: true,
        finanzierungsansicht: true,
        schnellsuche: true
    },
    fahrzeugsuche: {
        idExposee: 1572
    },
    style: {
        fontFamily: '"Muli", "Open Sans", "Arial", sans-serif',
        fontSize: 14,
        oldMuiTypography: {
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
        },
        primaryColor: '#0099da',
    },
}
 */

export type ConfigObject = {
    /** General configuration settings */
    general: {
        /**
         * Enable Debug-Mode.
         * @default false
         */
        debug: boolean,
        /**
         * Disable the whole HOMEPAGE-TOOL.
         * @default false
         */
        enable: boolean,
        /**
         * Hide all "Verfübar" Labels in Fahrzeugsuche & -detail.
         * @default false
         */
        hideVerfugbarLabels: boolean,
        /**
         * Customer ID.
         * @default undefined
         */
        idFirma: number | undefined,
        /**
         * Just a place holder for data-general-initialsearchpath.
         */
        readonly initialSearchPath: string | undefined,
        /**
         * Defines the customer as a group.
         * @default false
         */
        isGruppe: boolean,
        /**
         * Describes to use the landing page event_origin.
         * @default false
         */
        isLandingpage: boolean,
        /**
         * Used language for the HOMEPAGE-TOOL.
         * @default 'de-DE'
         */
        language: Lang,
        /**
         * Prefix for Page-Title.
         * @default undefined
         */
        metaTitlePrefix: string | undefined,
        /**
         * Seperator for Page-Title.
         * @default ' | '
         */
        metaTitleSeperator: string,
        /**
         * Suffix for Page-Title.
         * @default undefined
         */
        metaTitleSuffix: string | undefined,
        /**
         * Use the mockup view (no live data will be used, just placeholder).
         * @default false
         */
        mockup: boolean,
        /**
         * Name of the project
         * @default undefined
         */
        projectName: string,
        /**
         * Path on the webpage, where the module "fahrzeugsuche".
         * @default '/fahrzeugsuche'
         */
        searchPath: string,
        /**
         * DEBUG ONLY - Path on the webpage, where the module "fahrzeugsuche" in dark mode.
         * @default '/fahrzeugsuche'
         */
        searchPathDark: string,
        /**
         * Additional needed search attributes for searchPath (e.x. for Joomla or Typo3 pages).
         * @default undefined
         */
        searchPathSearch: string | undefined,
        /**
         * Optional redirection domain, if navigator should lead to another webpage.
         * @default undefined
         */
        redirectDomain: string | undefined,
        /**
         * Enable additional settings and styles for SHOW-ROOOOM.
         * @default false
         */
        showroooom: boolean,
        /**
         * Show Finanzierungen.
         * @default true
         */
        showFinanzierungen: boolean,
        /**
         * Show leasing.
         * @default true
         */
        showLeasing: boolean,
        /**
         * Show UPE + Transfer as price instead of vk.
         * @default false
         */
        showUpeWithTransferInsteadOfVk: boolean,
        /**
         * Show netto price calculated from UPE + Transfer instead of vkn.
         * @default false
         */
        showNettoUpeWithTransferInsteadOfVkn: boolean,
        /**
         * Enabled and defines the canonical root url followed by the seo optimized filter path.
         * @default undefined
         */
        useCanonicalLinks: boolean,
        /**
         * Enable the new SEO optimized routing system.
         * @default false
         * @deprecated
         */
        useOldRouter: boolean,
    }
    /** Contact Settings. */
    contact: {
        /** List of company IDs, that don't have lead system (only necessary for groups).
         * @default undefined
         */
        blacklistLeadsystem: number[] | undefined,
        /** Contact buttons settings.
         * @default { offsetX: 2, offsetY: 2, position: 'right' }
         */
        buttons: {
            offsetX: number,
            offsetY: number,
            position: 'left' | 'right',
        },
        /** Order of shown filters.
         * @default ['hersteller', 'modell', 'ezVon', 'motor']
         */
        carSearchFilters: ConfigFilter[],
        /** Custom fiewlds for CATCH XML.
         * @default undefined
         */
        catchCustomFields: {
            /** Marketing campaign.
             * @default undefined
             */
            campaign_name?: string | undefined;
            /** CRM dealer code.
             * @default undefined
             */
            crm_dealercode?: string | undefined;
            /** CRM dealer id.
             * @default undefined
             */
            crm_dealerid?: string | undefined;
            /** DMS dealder code.
             * @default undefined
             */
            dms_dealercode?: string | undefined;
            /** Lead channel.
             * @default undefined
             */
            lead_channel?: string | undefined;
            /** Lead source.
             * @default undefined
             */
            lead_source?: string | undefined;
            /** Lead source 2.
             * @default undefined
             */
            lead_source2?: string | undefined;
            /** Lead type.
             * @default undefined
             */
            lead_type?: string | undefined;
            /** Location string.
             * @default undefined
             */
            locationString?: string | undefined;
            /** Marketing campaign.
             * @default undefined
             */
            marketing_campaign?: string | undefined;
            /** Marketing url.
             * @default undefined
             */
            marketing_url?: string | undefined;
            /** Url.
             * @default undefined
             */
            url?: string | undefined;
            /** Url without protocol.
             * @default false
             */
            urlWithoutProtocol?: boolean;
        } | undefined;
        /** Is the mail receipient an automatic lead system?.
         * @default undefined
         */
        isLeadSystem: boolean | undefined,
        /** Is the mail receipient using a lead system from CATCH?.
         * @default undefined
         */
        isCatchLeadSystem: boolean | undefined,
        /** Link to the privacy statement.
         * @default undefined
         */
        linkDisclaimer: string | undefined,
        /** Requires phone number for contact form.
         * @default false
         */
        phoneRequired: boolean,
        /** When set, the "accept privacy" checkbox will be replaced with a text block, containing the specified text.
         * @default undefined
         */
        useTextInsteadOfPrivacyCheckBox: string | undefined,
    },
    /** Module Settings. */
    module: {
        /** Enable Aktionsslider.
         * @default false
         */
        aktionsslider: boolean,
        /** Enable ErweiterteSchnellsuche.
         * @default false
         */
        erweiterteSchnellsuche: boolean,
        /** Enable Fahrzeugliste.
         * @default false
         */
        fahrzeugliste: boolean,
        /** Enable Fahrzeugslider.
         * @default false
         */
        fahrzeugslider: boolean,
        /** Enable Fahrzeugsuche.
         * @default false
         */
        fahrzeugsuche: boolean,
        /** Enable Finanzierungsansicht.
         * @default false
         */
        finanzierungsansicht: boolean,
        /** Enable Schnellsuche.
         * @default false
         */
        schnellsuche: boolean,
        /** Enable Standorte.
         * @default false
         */
        standorte: boolean,
    },
    /** Settings for Aktionsslider. */
    aktionsslider: ModuleCommonProps & {
        /** Adjust the slides per page, if there are less than expected.
         * @default false
         */
        adjustColNumberByListLength: boolean,
    },
    /** API Settings. */
    api: {
        /** AutoUncle Settings. */
        autoUncle: {
            /** Map for linking locations with the departmentIds.
             * @default undefined
             */
            departmentIds: Record<number, string>
            /** Required for all Elements powered by AutoUncle.
             * @default undefined
             */
            id: string | undefined,
            /** Origin for Debugging.
             * @default undefined
             */
            origin: string | undefined,
            /** Enable Price Rating Widget powered by AutoUncle.
             * @default false
             */
            priceRating: boolean,
            /** Required for TradeIn Widget/Button powered by AutoUncle.
             * @default undefined
             */
            sourceName: string | undefined,
            /** Enable TradeIn Widget/Button powered by AutoUncle.
             * @default false
             */
            tradeIn: boolean,
        },
        /** Faaren Settings.*/
        faaren: {
            /** Api Key.
             * @default null
             */
            apiKey: string,
            /** Enable Faaren API.
             * @default false
             */
            enabled: boolean,
        },
        /** Tracking Settings.*/
        tracking: {
            /** Api Key.
             * @default null
             */
            apiKey: string | null,
            /** Custom tracking event scripts as string.
             * @default undefined
             */
            customScripts: {
                /** Custom tracking event for clicking on the google maps link.
                 * @default undefined
                 */
                mapsClick: string | undefined,
                /** Custom tracking event for clicking on a mail adress.
                 * @default undefined
                 */
                mailClick: string | undefined,
                /** Custom tracking event for clicking on a phone number.
                 * @default undefined
                 */
                phoneClick: string | undefined,
                /** Custom tracking event for clicking on printing the exposee.
                 * @default undefined
                 */
                printClick: string | undefined,
                /** Custom tracking event for clicking on a share type.
                 * @default undefined
                 */
                shareClick: string | undefined,
                /** Custom tracking event for clicking on the trade in link.
                 * @default undefined
                 */
                tradeInClick: string | undefined,
            } & ({
                /** Defines the handling (one function for all form types or splitted by form type).
                 * @default undefined
                 */
                commonSuccess: true,
                /** Custom tracking event for successfully sending a form (doesn't matter which type).
                 * @default undefined
                 */
                formCommonSuccess: string | undefined,
            } | {
                /** Defines the handling (one function for all form types or splitted by form type).
                 * @default undefined
                 */
                commonSuccess: false,
                /** Custom tracking event for successfully sending a form with type: general.
                 * @default undefined
                 */
                formGeneralSuccess: string | undefined,
                /** Custom tracking event for successfully sending a form with type: test drive.
                 * @default undefined
                 */
                formTestDriveSuccess: string | undefined,
                /** Custom tracking event for successfully sending a form with a attached financial service.
                 * @default undefined
                 */
                formFinancingSuccess: string | undefined,
            }),
            /** Enable Tracking.
             * @default true
             */
            enabled: boolean,
            /** Tracking provider.
             * @default 'ga'
             */
            provider: TrackingProvider,
        },
        /** WebCALC Settings.*/
        webcalc: {
            /** Enable WebCALC.
             * @default true
             */
            enabled: boolean,
            /** Domain WebCALC.
             * @default 'VW.NEW'
             */
            domain: string,
        },
    },
    /** Settings for Aktionsslider. */
    cartile: {
        /** Show badges and order them
         * @default []
         */
        badges: ('availableAt' | 'aktion')[],
        /** Choose visible data fields in grid
         * [G] at beginning for gridOnly
         * [L] at beginning for listOnly
         * @default {PKW: ['ez', 'km', 'ident', 'engine.leistung', 'engine.motor', 'getriebe', 'siegel', 'farbe', 'antrieb']}
         */
        infoGrid: Record<CarKategorie.MOTORRAD | 'default', ConfigInfoGridPrefixes<CarDataFields>[]>,
    },
    /** Settings for ErweiterteSchnellsuche. */
    erweiterteSchnellsuche: ModuleCommonProps & {
        /** Adjust the slides per page, if there are less than expected.
         * @default false
         */
        adjustColNumberByListLength: boolean,
        /** Display the filters full-width with the search button below in a new row
         * @default false
         */
        buttonBelowFilters: boolean,
        /** Order of shown filters in Schnellsuche.
         * @default ['hersteller', 'modell']
         */
        filter: (ConfigFilterPrefixes<ConfigFilter> | ConfigFilterOther)[],
        /** Show one filter per row.
         * @default false
         */
        oneFilterPerRow: boolean,
    },
    /** Settings for Fahrzeugslider. */
    fahrzeugslider: ModuleCommonProps & {
        /** Adjust the slides per page, if there are less than expected.
         * @default false
         */
        adjustColNumberByListLength: boolean,
        /** Order of shown filters in Schnellsuche.
         * @default ['hersteller', 'modell']
         */
        filter: (ConfigFilterPrefixes<ConfigFilter> | ConfigFilterOther)[],
    },
    /** Settings for Fahrzeugliste. */
    fahrzeugliste: ModuleCommonProps,
    /** Settings for Fahrzeugsuche. */
    fahrzeugsuche: ModuleCommonProps & {
        /** Replace filter.sofort label with a custom string.
         * @default undefined
         */
        customNameSofort: string | undefined,
        /** Show vehicle details only as single row.
         * @default true
         */
        detailsSingleRow: boolean,
        /** Order of shown filters.
         * @default {
         *  sidebar: ['hersteller', 'modell', 'standort', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
         *  top: ['hersteller', 'modell', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus']
         * }
         */
        filter: ConfigFilterObject
        /** Start displaying car pictures from the specified index.
        * @default undefined
        */
        firstImageIndex: number | undefined;
        /** Hide contact partners in location tab at vehicle detail view.
         * @default false
         */
        hideContactPersons: boolean,
        /** Hide autohaus-digital link in footer.
         * @default false
         */
        hideFooterAutohausDigitalLink: boolean,
        /** Hide hint about fuel usage data in footer.
         * @default false
         */
        hideFooterVerbrauch: boolean,
        /** Hide heading if page already has customer defined h1.
         * @default true
         */
        hideHeading: boolean,
        /** Hide Probefahrt FAB & textlink.
         * @default false
         */
        hideProbefahrt: boolean,
        /** Print ID for Exposee.
         * @default 1521
         */
        idExposee: number,
        /** Show List view by default.
         * @default false
         */
        listView: ViewOptionsView,
        /** Maximum count of columns for top filter.
         * @default 4
         * @deprecated to be removed (unnecessary since container css)
         */
        maxFilterCols: number,
        /** Order of shown filters.
         * @default undefined
         */
        shareButtons: ShareButtons[] | undefined,
        /** Add Design to Title in List.
         * @default false
         */
        titleWithDesign: boolean,
        /** Puts the Carlist-Filter on the top.
         * @default false
         */
        topFilter: boolean,
        /** Trade-In-Link.
         * @default undefined
         */
        tradeInLink: string | undefined,
        /** Use the second picture as main image.
         * @default true
         */
        useEyecatcher: boolean,
        /** Use location search map
        * @default false
        */
        useLocationMap: boolean,
    },
    /** Settings for detail view of car?. */
    fahrzeugdetail: {
        /** Additional infos under infogrid.
         * @default []
         */
        additionalInfo: {
            /** Icon name.
             * @default ''
             */
            icon?: string,
            /** String for translate-function.
             * @default ''
             */
            content: string,
        }[],
        /** Disable showing fuel usage data.
         * @default false
         */
        hideVerbrauch: boolean,
        /** Fields for infogrid.
         * @default {}
         */
        infoGrid: Record<CarKategorie.MOTORRAD | 'default', CarDataFields[]>,
        /** Enable showing the location image in the location details.
         * @default true
         */
        showLocationImage: boolean,
        /** Force permanent filters for "Ähnliche Fahrzeuge"
         * @default undefined
         */
        similarCarsFilter: ModuleCommonProps['forcedFilter'],
        /** Choose variant of detail page.
         * @default 1
         */
        variant: 1,
    },
    /** Settings for Finanzierungsansicht. */
    finanzierungsansicht: ModuleCommonProps & {
        /** Order of shown filters in Finanzierungsansicht.
         * @default ['monatlicheRateButton', 'sortButton']
         */
        filter: (ConfigFilterPrefixes<ConfigFilter> | ConfigFilterOther)[],
    },
    /** Settings for Install. */
    install: {
        /** Show setup for magic id.
         * @default false
         */
        showMagicIdSetup: boolean;
    };
    /** Settings for Schnellsuche. */
    schnellsuche: ModuleCommonProps & {
        /** Display the filters full-width with the search button below in a new row
         * @default false
         */
        buttonBelowFilters: boolean,
        /** Order of shown filters in Schnellsuche.
         * @default ['hersteller', 'modell']
         */
        filter: (ConfigFilterPrefixes<ConfigFilter> | ConfigFilterOther)[],
        /** Turn off border and padding.
         * @default false
         */
        noBorderPadding: boolean,
        /** Show one filter per row.
         * @default false
         */
        oneFilterPerRow: boolean,
    },
    /** Style Settings. */
    style: {
        /** Select different border radius for all elements.
         * @default '#fff'
         */
        background: string,
        /** Select different border radius for all elements.
         * @default 2
         */
        borderRadius: number,
        /** Enable dark mode.
         * @default false
         */
        darkMode: boolean,
        /** Default Font Family.
         * @default "Open Sans", "Arial", sans-serif
         */
        fontFamily: string,
        /** Default Font Size for small and large displays.
         * @default 14
         */
        fontSize: number,
        /** Override the global Material-UI components settings for customization. */
        muiComponents: (theme: Theme) => Components<Theme>,
        /** Override the global Material-UI typography settings for customization. */
        muiTypography: (theme: Theme) => TypographyOptions,
        /** Breakpoint definition.
         * @deprecated
        */
        oldMuiBreakpoints: Breakpoints['values'],
        /** Override the global Material-UI components settings for customization.
         * @deprecated
         */
        oldMuiComponents: Components,
        /** Override the global Material-UI typography settings for customization.
         * @deprecated
         */
        oldMuiTypography: TypographyOptions,
        /** Turn off animations.
         * @default false
         */
        noAnimations: boolean,
        /** Primary Color.
         * @default #0d1b48
         */
        primaryColor: string,
        /** Set offset for auto scroll to top.
         * @default undefined
         */
        scrollOffset: number | undefined,
        /** Secondary Color (currently not in use).
         * @default #c33c54
         */
        secondaryColor: string,
    },
    /* Automatically Generated Fields for Runtime */

    domContainerId: string | undefined,
};
